import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Deco from "./components/myProject/Deco"; // Import your Deco component
import AuthComponent from "./components/myProject/AuthComponent";
import MusicianTable from "./components/myProject/MusicianTable";
import Restaurant from "./components/myProject/Restaurant";
import Sample from "./components/myProject2nd/Sample";
import Exe1NameForm from "./components/JunExercise/Exe1NameForm";
import Exe2LoginForm from "./components/JunExercise/Exe2LoginForm";

import Exe3Email from "./components/JunExercise/Exe3Email";
import Exe4Subscription from "./components/JunExercise/Exe4Subscription";
import Exe5Language from "./components/JunExercise/Exe5Language";
import Exe6FeedbackForm from "./components/JunExercise/Exe6FeedbackForm";

// import VolumeComparisonApp from "./components/VolumeComparisionApp";
// import UserLogin from "./components/userlogin";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AuthComponent />} />
        <Route path="/deco" element={<Deco />} />
        <Route path="/musician" element={<MusicianTable />} />
        <Route path="/restaurant" element={<Restaurant />} />
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;

// import React from "react";
// import UploadVideo from "./components/myProject2nd/UploadVideo";
// import SharedVideos from "./components/myProject2nd/SharedVideos";

// function App() {
//   return (
//     <div>
//       <h1>Video Sharing App</h1>
//       <UploadVideo />
//       <SharedVideos />
//     </div>
//   );
// }

// export default App;
