import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Deco.css"; // Import your CSS file here

const Deco = () => {
  const navigate = useNavigate(); // Initialize navigate

  const [decorations, setDecorations] = useState([
    {
      id: 1,
      name: "Floral Arrangements",
      style: "Romantic",
      description:
        "Beautiful floral arrangements to enhance your wedding decor.",
    },
    {
      id: 2,
      name: "Fairy Lights",
      style: "Elegant",
      description: "Soft fairy lights for a magical atmosphere.",
    },
    // Add more initial decoration options as needed
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [formState, setFormState] = useState({
    id: null,
    name: "",
    style: "",
    description: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  const filteredDecorations = decorations.filter((decoration) =>
    decoration.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddOrUpdate = (e) => {
    e.preventDefault();
    if (isEditing) {
      setDecorations(
        decorations.map((decoration) =>
          decoration.id === formState.id ? formState : decoration
        )
      );
      setIsEditing(false);
    } else {
      const newDecoration = { ...formState, id: Date.now() }; // Simple ID generation
      setDecorations([...decorations, newDecoration]);
    }
    setFormState({
      id: null,
      name: "",
      style: "",
      description: "",
    });
  };

  const handleEdit = (decoration) => {
    setIsEditing(true);
    setFormState(decoration);
  };

  const handleDelete = (id) => {
    setDecorations(decorations.filter((decoration) => decoration.id !== id));
  };

  const handleBack = () => {
    navigate("/"); // Navigate to the homepage
  };

  return (
    <div className="deco-list-container">
      <h2>Wedding Decorations</h2>
      <input
        type="text"
        placeholder="Search decorations..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <form onSubmit={handleAddOrUpdate} className="deco-form">
        <input
          type="text"
          placeholder="Decoration Name"
          value={formState.name}
          onChange={(e) => setFormState({ ...formState, name: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Style"
          value={formState.style}
          onChange={(e) =>
            setFormState({ ...formState, style: e.target.value })
          }
          required
        />
        <textarea
          placeholder="Description"
          value={formState.description}
          onChange={(e) =>
            setFormState({ ...formState, description: e.target.value })
          }
          required
        />
        <button type="submit">{isEditing ? "Update" : "Add"} Decoration</button>
        {isEditing && (
          <button type="button" onClick={() => setIsEditing(false)}>
            Cancel
          </button>
        )}
      </form>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Style</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredDecorations.map((decoration) => (
            <tr key={decoration.id}>
              <td>{decoration.name}</td>
              <td>{decoration.style}</td>
              <td>{decoration.description}</td>
              <td>
                <button onClick={() => handleEdit(decoration)}>Edit</button>
                <button onClick={() => handleDelete(decoration.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={handleBack} style={{ marginTop: "20px" }}>
        Back to Homepage
      </button>
    </div>
  );
};

export default Deco;
