import React, { useState } from "react";
import "./Restaurant.css"; // Import your CSS file here
import { useNavigate } from "react-router-dom";

const Restaurant = () => {
  const navigate = useNavigate();
  const [restaurants, setRestaurants] = useState([
    {
      id: 1,
      name: "Nobu Kuala Lumpur",
      location: "Menara 3 Petronas, Kuala Lumpur City Centre",
      capacity: "100 - 200 guests",
      cuisine: "Japanese, Fusion",
      description:
        "A world-renowned restaurant known for its innovative dishes and stunning views of the Petronas Twin Towers.",
    },
    {
      id: 2,
      name: "The Majestic Hotel Kuala Lumpur",
      location: "5 Jalan Sultan Hishamuddin, Kuala Lumpur",
      capacity: "50 - 300 guests",
      cuisine: "Local, Western",
      description:
        "A heritage hotel with a charming ballroom, perfect for traditional and modern weddings.",
    },
    // Add more initial restaurants as needed
  ]);

  const handleBack = () => {
    navigate("/"); // Navigate to the homepage
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [formState, setFormState] = useState({
    id: null,
    name: "",
    location: "",
    capacity: "",
    cuisine: "",
    description: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  const filteredRestaurants = restaurants.filter((restaurant) =>
    restaurant.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddOrUpdate = (e) => {
    e.preventDefault();
    if (isEditing) {
      setRestaurants(
        restaurants.map((restaurant) =>
          restaurant.id === formState.id ? formState : restaurant
        )
      );
      setIsEditing(false);
    } else {
      const newRestaurant = { ...formState, id: Date.now() }; // Simple ID generation
      setRestaurants([...restaurants, newRestaurant]);
    }
    setFormState({
      id: null,
      name: "",
      location: "",
      capacity: "",
      cuisine: "",
      description: "",
    });
  };

  const handleEdit = (restaurant) => {
    setIsEditing(true);
    setFormState(restaurant);
  };

  const handleDelete = (id) => {
    setRestaurants(restaurants.filter((restaurant) => restaurant.id !== id));
  };

  return (
    <div className="restaurant-list-container">
      <h2>Wedding Venues in Kuala Lumpur</h2>
      <input
        type="text"
        placeholder="Search restaurants..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <form onSubmit={handleAddOrUpdate} className="restaurant-form">
        <input
          type="text"
          placeholder="Restaurant Name"
          value={formState.name}
          onChange={(e) => setFormState({ ...formState, name: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Location"
          value={formState.location}
          onChange={(e) =>
            setFormState({ ...formState, location: e.target.value })
          }
          required
        />
        <input
          type="text"
          placeholder="Capacity"
          value={formState.capacity}
          onChange={(e) =>
            setFormState({ ...formState, capacity: e.target.value })
          }
          required
        />
        <input
          type="text"
          placeholder="Cuisine"
          value={formState.cuisine}
          onChange={(e) =>
            setFormState({ ...formState, cuisine: e.target.value })
          }
          required
        />
        <textarea
          placeholder="Description"
          value={formState.description}
          onChange={(e) =>
            setFormState({ ...formState, description: e.target.value })
          }
          required
        />
        <button type="submit">{isEditing ? "Update" : "Add"} Restaurant</button>
        {isEditing && (
          <button type="button" onClick={() => setIsEditing(false)}>
            Cancel
          </button>
        )}
      </form>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Capacity</th>
            <th>Cuisine</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredRestaurants.map((restaurant) => (
            <tr key={restaurant.id}>
              <td>{restaurant.name}</td>
              <td>{restaurant.location}</td>
              <td>{restaurant.capacity}</td>
              <td>{restaurant.cuisine}</td>
              <td>{restaurant.description}</td>
              <td>
                <button onClick={() => handleEdit(restaurant)}>Edit</button>
                <button onClick={() => handleDelete(restaurant.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={handleBack} style={{ marginTop: "20px" }}>
        Back to Homepage
      </button>
    </div>
  );
};

export default Restaurant;
