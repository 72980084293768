import React, { useState, useEffect } from "react";
import { auth, googleProvider } from "../firebaseConfig";
import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
// import MusicianTable from "./MusicianTable";
import "./AuthComponent.css";

import MusicianTable from "./MusicianTable";

const db = getFirestore();

const AuthComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  const [userRoles, setUserRoles] = useState({
    isAdmin: false,
    isSuperAdmin: false,
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const roles = await fetchUserRoles(user.email);
        setUserRoles(roles);
      } else {
        setUser(null);
        setUserRoles({ isAdmin: false, isSuperAdmin: false });
      }
    });
    return unsubscribe;
  }, []);

  const fetchUserRoles = async (userEmail) => {
    try {
      const userDoc = await getDoc(doc(db, "users", userEmail));
      return userDoc.exists()
        ? userDoc.data()
        : { isAdmin: false, isSuperAdmin: false };
    } catch (error) {
      console.error("Error fetching user roles:", error);
      return { isAdmin: false, isSuperAdmin: false };
    }
  };

  const assignAdminRole = async (targetEmail, isAdminStatus) => {
    if (!userRoles.isSuperAdmin) return;

    try {
      const userRef = doc(db, "users", targetEmail);
      await updateDoc(userRef, { isAdmin: isAdminStatus });
      alert(`Admin status updated for ${targetEmail}`);
    } catch (error) {
      console.error("Error updating admin status:", error);
      setError("Failed to update admin status.");
    }
  };

  const handleSignup = async () => {
    if (!email || !password) {
      setError("Please fill out both email and password.");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
      setError("");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogin = async () => {
    if (!email || !password) {
      setError("Please fill out both email and password.");
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
      setError("");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      setUser(result.user);

      setError("");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      setError(error.message);
    }
  };

  // If user is not an admin, show access denied message
  if (userRoles.isAdmin) {
    return <p>Access Denied. You must be an admin to view this page.</p>;
  }

  return (
    <div className="container">
      <h2 className="header">Music-Event Data</h2>
      {error && <p className="error">{error}</p>}

      {user ? (
        <>
          <p>Welcome, {user.email}</p>
          <button className="button" onClick={handleLogout}>
            Logout
          </button>
          {userRoles.isAdmin && <p>You are an admin and can edit the table.</p>}
          {userRoles.isSuperAdmin && (
            <div>
              <h3>Admin Controls</h3>
              <input
                type="email"
                placeholder="Enter user email to toggle admin status"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                onClick={() => assignAdminRole(email, !userRoles.isAdmin)}
                className="button"
              >
                Toggle Admin Status
              </button>
            </div>
          )}
          <MusicianTable isAdmin={userRoles.isAdmin} />
        </>
      ) : (
        <>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input"
          />
          <div className="button-container">
            <button className="button" onClick={handleSignup}>
              Sign Up
            </button>
            <button className="button" onClick={handleLogin}>
              Login
            </button>
          </div>
          <button className="google-button" onClick={handleGoogleSignIn}>
            Sign in with Google
          </button>
        </>
      )}
    </div>
  );
};

export default AuthComponent;
