// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // firebaseConfig.js
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyCGjFnBs1g3DHyuRD8Qu4M0j-DQ6VeqEbc",
  authDomain: "fir-authentication-lesso-c5bba.firebaseapp.com",
  projectId: "fir-authentication-lesso-c5bba",
  storageBucket: "fir-authentication-lesso-c5bba.appspot.com",
  messagingSenderId: "1054830127749",
  appId: "1:1054830127749:web:6863bde2d449b0205957ec",
  measurementId: "G-3VW5CXYVL7",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const auth = firebaseApp.auth();

// const db = getFirestore(app);

// export { db };
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider(); // Initialize GoogleAuthProvider
const firestorage = getStorage(app);
export { db, auth, googleProvider, firestorage };
