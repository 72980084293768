// import React, { useState, useEffect } from "react";
// import { db } from "../firebaseConfig";
// import {
//   collection,
//   addDoc,
//   updateDoc,
//   deleteDoc,
//   doc,
//   getDocs,
// } from "firebase/firestore";
// import { useNavigate } from "react-router-dom";
// import "./MusicianTable.css";

// const MusicianTable = () => {
//   const [musicians, setMusicians] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [formState, setFormState] = useState({
//     id: null,
//     name: "",
//     instrument: "",
//     contact: "",
//   });
//   const [isEditing, setIsEditing] = useState(false);
//   const [loading, setLoading] = useState(true); // Loading state
//   const musiciansCollection = collection(db, "musicians");
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchMusicians = async () => {
//       const data = await getDocs(musiciansCollection);
//       const musiciansList = data.docs.map((doc) => ({
//         ...doc.data(),
//         id: doc.id,
//       }));

//       setMusicians(musiciansList);
//       setLoading(false); // Data loaded, set loading to false
//     };
//     fetchMusicians();
//   }, []);

//   const filteredMusicians = musicians.filter(
//     (musician) =>
//       musician.name &&
//       musician.name.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const handleAdd = async () => {
//     if (!formState.name || !formState.instrument) return;

//     const newMusician = {
//       name: formState.name,
//       instrument: formState.instrument,
//       contact: formState.contact,
//     };

//     const docRef = await addDoc(musiciansCollection, newMusician);
//     setMusicians([...musicians, { ...newMusician, id: docRef.id }]);
//     setFormState({ id: null, name: "", instrument: "", contact: "" });
//   };

//   const handleUpdate = async () => {
//     if (!formState.id) return;

//     const musicianDoc = doc(db, "musicians", formState.id);
//     await updateDoc(musicianDoc, {
//       name: formState.name,
//       instrument: formState.instrument,
//     });

//     setMusicians(
//       musicians.map((musician) =>
//         musician.id === formState.id ? formState : musician
//       )
//     );
//     setIsEditing(false);
//     setFormState({ id: null, name: "", instrument: "" });
//   };

//   const handleEdit = (musician) => {
//     setIsEditing(true);
//     setFormState(musician);
//   };

//   const handleDelete = async (id) => {
//     const musicianDoc = doc(db, "musicians", id);
//     await deleteDoc(musicianDoc);

//     setMusicians(musicians.filter((musician) => musician.id !== id));
//   };

//   const handleCategoryChange = (e) => {
//     const selectedCategory = e.target.value;
//     if (selectedCategory === "deco") {
//       navigate("/deco");
//     } else if (selectedCategory === "restaurant") {
//       navigate("/restaurant");
//     } else if (selectedCategory === "musician") {
//       navigate("/musician");
//     }
//   };

//   const handleBack = () => {
//     navigate("/");
//   };

//   return (
//     <div className="musician-table-container">
//       <h2>Musician Manager</h2>
//       <select onChange={handleCategoryChange} className="category-select">
//         <option value="">Select a Category</option>
//         <option value="deco">Deco</option>
//         <option value="restaurant">Restaurant</option>
//         <option value="musician">Musician</option>
//       </select>

//       <div>
//         <input
//           type="text"
//           placeholder="Search musicians..."
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           className="search-input"
//         />
//       </div>

//       {loading ? ( // Check loading state
//         <p>Data is loading...</p>
//       ) : (
//         <>
//           <form onSubmit={(e) => e.preventDefault()} className="musician-form">
//             <input
//               type="text"
//               placeholder="Musician Name"
//               value={formState.name}
//               onChange={(e) =>
//                 setFormState({ ...formState, name: e.target.value })
//               }
//               className="form-input"
//             />
//             <input
//               type="text"
//               placeholder="Instrument"
//               value={formState.instrument}
//               onChange={(e) =>
//                 setFormState({ ...formState, instrument: e.target.value })
//               }
//               className="form-input"
//             />
//             <button
//               className="form-button"
//               onClick={isEditing ? handleUpdate : handleAdd}
//             >
//               {isEditing ? "Update" : "Add"} Musician
//             </button>
//             {isEditing && (
//               <button
//                 className="form-button cancel-button"
//                 onClick={() => setIsEditing(false)}
//               >
//                 Cancel
//               </button>
//             )}
//           </form>
//           <table className="musician-table">
//             <thead>
//               <tr>
//                 <th>Name</th>
//                 <th>Instrument</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredMusicians.map((musician) => (
//                 <tr key={musician.id}>
//                   <td>{musician.name}</td>
//                   <td>{musician.instrument}</td>
//                   <td>
//                     <button
//                       className="edit-button"
//                       onClick={() => handleEdit(musician)}
//                     >
//                       Edit
//                     </button>
//                     <button
//                       className="delete-button"
//                       onClick={() => handleDelete(musician.id)}
//                     >
//                       Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </>
//       )}
//       <button onClick={handleBack} style={{ marginTop: "20px" }}>
//         Back to Homepage
//       </button>
//     </div>
//   );
// };

// export default MusicianTable;

import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./MusicianTable.css";

const MusicianTable = () => {
  const [musicians, setMusicians] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [formState, setFormState] = useState({
    id: null,
    name: "",
    instrument: "",
    contact: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showContact, setShowContact] = useState({}); // Track visibility of each contact
  const musiciansCollection = collection(db, "musicians");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMusicians = async () => {
      const data = await getDocs(musiciansCollection);
      const musiciansList = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setMusicians(musiciansList);
      setLoading(false);
    };
    fetchMusicians();
  }, []);

  const filteredMusicians = musicians.filter(
    (musician) =>
      musician.name &&
      musician.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAdd = async () => {
    if (!formState.name || !formState.instrument || !formState.contact) return;

    const newMusician = {
      name: formState.name,
      instrument: formState.instrument,
      contact: formState.contact,
    };

    const docRef = await addDoc(musiciansCollection, newMusician);
    setMusicians([...musicians, { ...newMusician, id: docRef.id }]);
    setFormState({ id: null, name: "", instrument: "", contact: "" });
  };

  const handleUpdate = async () => {
    if (!formState.id) return;

    const musicianDoc = doc(db, "musicians", formState.id);
    await updateDoc(musicianDoc, {
      name: formState.name,
      instrument: formState.instrument,
      contact: formState.contact,
    });

    setMusicians(
      musicians.map((musician) =>
        musician.id === formState.id ? formState : musician
      )
    );
    setIsEditing(false);
    setFormState({ id: null, name: "", instrument: "", contact: "" });
  };

  const handleEdit = (musician) => {
    setIsEditing(true);
    setFormState(musician);
  };

  const handleDelete = async (id) => {
    const musicianDoc = doc(db, "musicians", id);
    await deleteDoc(musicianDoc);
    setMusicians(musicians.filter((musician) => musician.id !== id));
  };

  const handleToggleContact = (id) => {
    setShowContact((prevShowContact) => ({
      ...prevShowContact,
      [id]: !prevShowContact[id], // Toggle the contact visibility for this musician
    }));
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    if (selectedCategory === "deco") {
      navigate("/deco");
    } else if (selectedCategory === "restaurant") {
      navigate("/restaurant");
    } else if (selectedCategory === "musician") {
      navigate("/musician");
    }
  };

  const handleBack = () => {
    navigate("/");
  };

  return (
    <div className="musician-table-container">
      <h2>Musician Manager</h2>
      <select onChange={handleCategoryChange} className="category-select">
        <option value="">Select a Category</option>
        <option value="deco">Deco</option>
        <option value="restaurant">Restaurant</option>
        <option value="musician">Musician</option>
      </select>

      <div>
        <input
          type="text"
          placeholder="Search musicians..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      {loading ? (
        <p>Data is loading...</p>
      ) : (
        <>
          <form onSubmit={(e) => e.preventDefault()} className="musician-form">
            <input
              type="text"
              placeholder="Musician Name"
              value={formState.name}
              onChange={(e) =>
                setFormState({ ...formState, name: e.target.value })
              }
              className="form-input"
            />
            <input
              type="text"
              placeholder="Instrument"
              value={formState.instrument}
              onChange={(e) =>
                setFormState({ ...formState, instrument: e.target.value })
              }
              className="form-input"
            />
            <input
              type="text"
              placeholder="Contact"
              value={formState.contact}
              onChange={(e) =>
                setFormState({ ...formState, contact: e.target.value })
              }
              className="form-input"
            />
            <button
              className="form-button"
              onClick={isEditing ? handleUpdate : handleAdd}
            >
              {isEditing ? "Update" : "Add"} Musician
            </button>
            {isEditing && (
              <button
                className="form-button cancel-button"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>
            )}
          </form>
          <table className="musician-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Instrument</th>
                <th>Contact</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredMusicians.map((musician) => (
                <tr key={musician.id}>
                  <td>{musician.name}</td>
                  <td>{musician.instrument}</td>
                  <td>
                    {showContact[musician.id] ? (
                      musician.contact
                    ) : (
                      <button
                        className="show-contact-button"
                        onClick={() => handleToggleContact(musician.id)}
                      >
                        Show Contact
                      </button>
                    )}
                  </td>
                  <td>
                    <button
                      className="edit-button"
                      onClick={() => handleEdit(musician)}
                    >
                      Edit
                    </button>
                    <button
                      className="delete-button"
                      onClick={() => handleDelete(musician.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      <button onClick={handleBack} style={{ marginTop: "20px" }}>
        Back to Homepage
      </button>
    </div>
  );
};

export default MusicianTable;
